import api, { endpoints } from '../services/api';
import { GitlabPipelinesResponse } from './entities';

interface StopPipelineProps {
  projectSelectedID: string;
  onCompleted: (status: string) => void;
}

export default function stopPipeline({
  projectSelectedID,
  onCompleted,
}: StopPipelineProps): void {
  const { gitlab } = api();
  setTimeout(() => {
    gitlab
      .get(endpoints.gitlab.get.listPipelines(projectSelectedID))
      .then((res: GitlabPipelinesResponse) => {
        const toStop: any = res.data?.filter(
          (pipeline) =>
            pipeline.status === 'running' || pipeline.status === 'pending'
        );

        if (toStop?.length === 0) {
          onCompleted('success');
          return;
        }

        gitlab
          .post(
            endpoints.gitlab.post.cancelPipelines(
              projectSelectedID,
              toStop[0].id
            )
          )
          .then(() => {
            onCompleted('success');
          })
          .catch(() => onCompleted('error'));
      })
      .catch(() => onCompleted('error'));
  }, 5000);
}
