export default function storage() {
  const get = (key: string) => {
    const data: any = window.localStorage.getItem(key);
    return JSON.parse(data);
  };

  const set = (key: string, value: any) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const clearAll = () => window.localStorage.clear();

  const removeOne = (key: string) => window.localStorage.removeItem(key);

  const onLs = (
    key: string,
    onChanges: (data: { oldValue: string; newValue: string }) => void
  ) => {
    window.addEventListener('storage', function (e) {
      const { oldValue, newValue } = e;
      if (e.key === key) {
        onChanges({ oldValue: oldValue ?? '', newValue: newValue ?? '' });
      }
    });
  };

  return { get, set, clearAll, removeOne, onLs };
}

export const keyNames = {
  projects: 'snake_projects',
  selectedProject: 'snake_selected_project',
  tokens: 'snake_tokens',
  history: 'snake_history',
  gitlabGroupID: 'snake_gitlab_group_id',
  tokenChange: 'snake_token_change',
  qrCodes: 'snake_qrcodes',
  changeLog: 'snake_changelog',
  lang: 'snake_language',
  taskManager: 'snake_task_manager',
};
