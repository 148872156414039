import React, { useState, useEffect, useRef } from 'react';
import './style.css';

const SnakeGame: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [snake, setSnake] = useState<{ x: number; y: number }[]>([
    { x: 10, y: 10 },
  ]);
  const [food, setFood] = useState<{ x: number; y: number }>({ x: 20, y: 10 });
  const direction = useRef<'up' | 'down' | 'left' | 'right'>('right');
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  document.body.style.overflow = 'hidden';

  const generateFood = () => {
    let x = Math.floor(Math.random() * 30);
    let y = Math.floor(Math.random() * 30);
    setFood({ x, y });
  };

  const moveSnake = () => {
    let head = { ...snake[0] };
    switch (direction.current) {
      case 'up':
        head.y--;
        break;
      case 'down':
        head.y++;
        break;
      case 'left':
        head.x--;
        break;
      case 'right':
        head.x++;
        break;
    }

    if (
      head.x < 0 ||
      head.x >= 30 ||
      head.y < 0 ||
      head.y >= 30 ||
      snake.some((segment) => segment.x === head.x && segment.y === head.y)
    ) {
      setGameOver(true);
      return;
    }

    setSnake([head, ...snake.slice(0, -1)]);

    if (head.x === food.x && head.y === food.y) {
      if (!gameOver) setScore(score + 1);
      generateFood();
      setSnake([head, ...snake]);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp':
        if (direction.current !== 'down') direction.current = 'up';
        break;
      case 'ArrowDown':
        if (direction.current !== 'up') direction.current = 'down';
        break;
      case 'ArrowLeft':
        if (direction.current !== 'right') direction.current = 'left';
        break;
      case 'ArrowRight':
        if (direction.current !== 'left') direction.current = 'right';
        break;
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#122507';
    ctx.fillRect(food.x * 10, food.y * 10, 10, 10);
    ctx.fillStyle = '#122507';
    snake.forEach((segment) =>
      ctx.fillRect(segment.x * 10, segment.y * 10, 10, 10)
    );
  }, [snake, food]);

  useEffect(() => {
    const interval = setInterval(moveSnake, 100);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snake]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const resetGame = () => {
    setSnake([{ x: 10, y: 10 }]);
    setFood({ x: 20, y: 10 });
    direction.current = 'right';
    setGameOver(false);
    setScore(0);
  };

  return (
    <div className="container-game">
      <div className="screen-frame">
        <p>Score: {score}</p>
        <canvas
          ref={canvasRef}
          width={300}
          height={300}
          className="snake-canvas"
        />
        {gameOver && (
          <div className="game-over">
            <h2>Game Over</h2>
            <button onClick={resetGame}>Play Again</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SnakeGame;
