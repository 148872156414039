import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { DataBug } from '../../utils/entities';
import { msgFix } from './message';
import { Box, TextField } from '@mui/material';
import { t } from 'i18next';

interface ModalFixProps {
  show: boolean;
  setShow: (show: boolean) => void;
  setData: (data: Object) => void;
}

interface FormFixProps {
  data: DataBug;
  handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
}

export function FormFix({ data, handleChange }: FormFixProps) {
  const { t } = useTranslation();
  return (
    <Box
      component="form"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      gap="1rem"
    >
      <TextField
        label={t('forms.fix.description')}
        name="problem"
        value={data.problem}
        type="text"
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label={t('forms.fix.behaviorExpected')}
        name="expected"
        value={data.expected}
        type="text"
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label={t('forms.fix.validation')}
        name="story"
        value={data.story}
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label={t('forms.fix.enviroment')}
        name="environment"
        value={data.environment}
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label={t('forms.fix.browser')}
        name="browser"
        value={data.browser}
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
    </Box>
  );
}

export default function ModalFix({
  show,
  setShow,
  setData,
}: ModalFixProps): ReactElement {
  const [showForm, setShowForm] = useState(false);
  const [fix, setFix] = useState({
    problem: '',
    expected: '',
    story: '',
    environment: '',
    browser: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFix({
      ...fix,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFix({
      problem: '',
      expected: '',
      story: '',
      environment: '',
      browser: '',
    });
  };

  return (
    <>
      <Modal
        title={t('forms.fillForm')}
        text={t('forms.fix.youSelected')}
        show={show}
        confirmLabel={t('buttons.yes')}
        cancelLabel={t('buttons.no')}
        onConfirm={() => {
          setShowForm(true);
          setShow(false);
        }}
        onCancel={() => {
          setShow(false);
          clearForm();
        }}
      />
      {showForm ? (
        <Modal
          title={t('forms.fix.formFix')}
          show={showForm}
          confirmLabel={t('buttons.send')}
          cancelLabel={t('buttons.cancel')}
          onConfirm={() => {
            setData(msgFix(fix));
            setShowForm(false);
          }}
          onCancel={() => {
            setShowForm(false);
            clearForm();
          }}
        >
          <FormFix
            data={fix}
            handleChange={handleChange}
          />
        </Modal>
      ) : null}
    </>
  );
}
