import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { FetchedDataProvider } from './context/fetchedData';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ThemeProvider } from '@mui/material';
import theme from './utils/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/general.css';
import './styles/grid.css';
import './locale/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <FetchedDataProvider>
      <ThemeProvider theme={theme}>
        <ToastContainer
          pauseOnFocusLoss
          theme="dark"
        />
        <App />
      </ThemeProvider>
    </FetchedDataProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
