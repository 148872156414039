import { createContext, useState } from 'react';

type FetchedDataProviderProps = {
  children: React.ReactNode;
};
type FetchedDataContextProps = {
  data: {
    pivotalProjects: any[];
    gitlabProjects: any[];
    gitlabGroups: any[];
    pageSelected: number;
  };
  setData: Function;
};

const FetchedDataContext = createContext<FetchedDataContextProps>(
  {} as FetchedDataContextProps
);

const FetchedDataProvider = ({ children }: FetchedDataProviderProps) => {
  const [data, setData] = useState({
    pivotalProjects: [],
    gitlabProjects: [],
    gitlabGroups: [],
    pageSelected: 100,
  });

  return (
    <FetchedDataContext.Provider value={{ data, setData }}>
      <>{children}</>
    </FetchedDataContext.Provider>
  );
};

export { FetchedDataContext, FetchedDataProvider };
