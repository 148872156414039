import dayjs from 'dayjs';
import { DataBug, DataHotfix } from '../../../utils/entities';
export const msgHotfix = (data: DataHotfix) =>
  `
  # INCIDENT REPORT

**Hospital:** Carenet
**Incident date/time:** ${dayjs(data.dateIncident).format('DD/MM/YY HH:mm')}
**Report date/time:** ${dayjs(data.dateReport).format('DD/MM/YY HH:mm')}
**Reported by:** ${data.reportedBy}

Were any other hospitals impacted? If yes, which one? ${data.otherHospitals}

**PROBLEM REPORTED:**
${data.problemReported} 

**ROOT CAUSE:**
${data.rootCause}

**SOLUTION**
${data.solution}

**VALIDATION**
Descreva aqui os passos usados para validar e insira evidencias de que o problema reportado foi sanado)
${data.validation}
  `;

export const msgFix = (data: DataBug) =>
  `
**Descreva o problema atual:**
${data.problem}

**Descreva o comportamento esperado:**
${data.expected}

**Story/critério de validação:**
${data.story}

**Ambiente:**
${data.environment}

**Browser:**
${data.browser || 'Chrome/Edge'}

**Inserir em anexo a evidência do bug**
  `;
