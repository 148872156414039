import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUs from './en-us.json';
import ptBr from './pt-br.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enUs,
    },
    pt: {
      translation: ptBr,
    },
  },
  lng: 'pt',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
