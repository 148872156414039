import axios from 'axios';
import storage, { keyNames } from '../utils/storage';
import typeOfStory from '../utils/typeOfStory';

const { get } = storage();

export default function api() {
  const gitlab = axios.create({
    baseURL: 'https://gitlab.com/api/v4',
    headers: {
      'PRIVATE-TOKEN': get(keyNames.tokens)?.gitlab,
    },
  });

  const pivotal = axios.create({
    baseURL: 'https://www.pivotaltracker.com/services/v5',
    headers: {
      'X-TrackerToken': get(keyNames.tokens)?.pivotal,
    },
  });

  const clickup = axios.create({
    baseURL: 'https://api.clickup.com/api/v2',
    headers: {
      Authorization: get(keyNames.tokens)?.clickup,
    },
  });

  const toggl = axios.create({
    baseURL: 'https://api.track.toggl.com/api/v9',
    headers: {
      'Content-Type': 'application/json',
    },
    auth: {
      username: get(keyNames.tokens)?.toggl,
      password: 'api_token',
    },
  });

  return { gitlab, pivotal, toggl, clickup };
}

const gitlabGroupID = get(keyNames.gitlabGroupID);

export const endpoints = {
  gitlab: {
    get: {
      groups: '/groups',
      projects: `/groups/${gitlabGroupID}/projects?page=1&per_page=1000`,
      branches: (projectsId: string) =>
        `/projects/${projectsId}/repository/branches/?page=1&per_page=1000`,
      compare: (projectsId: string, from: string, to: string) =>
        `/projects/${projectsId}/repository/compare?from=${from}&to=${to}`,
      listPipelines: (projectsId: string) =>
        `/projects/${projectsId}/pipelines`,
    },
    post: {
      createBranch: (
        projectId: string,
        branchNameComp: string,
        originBranch: string
      ) => {
        return `/projects/${projectId}/repository/branches?branch=${branchNameComp}&ref=${originBranch}`;
      },
      createMR: (
        gitlabProjectID: string,
        sourceBranch: string,
        targetBranch: string,
        mrType: string,
        mrIcon: string,
        taskName: string,
        taskID: string
      ) => {
        const attr = `id=${gitlabProjectID}&source_branch=${sourceBranch}&target_branch=${targetBranch}&squash=true&remove_source_branch=true&title=${encodeURIComponent(`Draft:${mrIcon} ${taskID} - ${taskName}`)}&description=${encodeURIComponent(`${mrIcon} ${mrType}: ${taskName}\n\n[Delivers ${taskID}]`)}`;
        return `/projects/${gitlabProjectID}/merge_requests?${attr}`;
      },
      cancelPipelines: (projectId: string, pipelineID: number) =>
        `/projects/${projectId}/pipelines/${pipelineID}/cancel`,
    },
  },
  pivotal: {
    get: {
      me: '/me',
      taskData: (projectId: string, storyId: string) =>
        `/projects/${projectId}/${typeOfStory(storyId)}/${storyId.replaceAll('#', '')}`,
    },
    put: {
      changeStatus: (projectId: string, storyId: string) =>
        `/projects/${projectId}/stories/${storyId.replaceAll('#', '')}?current_state=started`,
      description: (
        projectId: string,
        storyId: string,
        description: string
      ) => {
        return `/projects/${projectId}/stories/${storyId.replaceAll('#', '')}?description=${description}`;
      },
    },
  },
  clickup: {
    get: {
      me: '/user',
      taskData: (taskId: string) => `/task/${taskId}`,
    },
    put: {
      changeStatus: (taskId: string) => `/task/${taskId}`,
    },
  },
  toggl: {
    get: {
      me: '/me',
    },
    post: {
      createTimer: `/workspaces/${get(keyNames.tokens)?.toggl_workspace}/time_entries`,
    },
  },
};
