import logo from '../assets/logo.svg';
import pivotal from '../assets/pivotal.svg';
import gitlab from '../assets/gitlab.svg';
import toggl from '../assets/toggl.svg';
import clickup from '../assets/clickup1.svg';

const icons = {
  logo,
  pivotal,
  gitlab,
  toggl,
  clickup,
};

export default icons;
