const mrType = [
  { id: 1, label: '✨ Feature', value: 'feat', icon: '✨', prefix: 'feature' },
  { id: 2, label: '🐛 Fix', value: 'fix', icon: '🐛', prefix: 'fix' },
  { id: 3, label: '📚 Docs', value: 'docs', icon: '📚', prefix: 'feature' },
  { id: 4, label: '💄 Style', value: 'style', icon: '💄', prefix: 'feature' },
  {
    id: 5,
    label: '♻️ Refactor',
    value: 'refactor',
    icon: '♻️',
    prefix: 'feature',
  },
  { id: 6, label: '⚡️ Perf', value: 'perf', icon: '⚡️', prefix: 'feature' },
  { id: 7, label: '✅ Test', value: 'test', icon: '✅', prefix: 'feature' },
  { id: 8, label: '🛠 Build', value: 'build', icon: '🛠', prefix: 'feature' },
  { id: 9, label: '👷 CI', value: 'ci', icon: '👷', prefix: 'feature' },
  { id: 10, label: '🔥 Hotfix', value: 'hotfix', icon: '🔥', prefix: 'hotfix' },
  { id: 99, label: '🚀 Feature', value: 'feat', icon: '🚀', prefix: 'feature' },
];

export default mrType;
